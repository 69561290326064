const baseUrl = 'https://ar3biai-d5c7f4185f18.herokuapp.com';

export const deleteTarefa = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/tarefa/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao excluir registro da tarefa:', response.statusText);
        }
        return response;
    } catch (error) {
        console.error('Erro ao excluir registro da tarefa:', error);
        throw error; // Re-throw para tratar o erro onde for chamado
    }
};

export const listTarefas = async (token) => {
    try {
        const response = await fetch(`${baseUrl}/tarefa/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao listar registros de tarefas:', response.statusText);
        }
        return await response.json();
    } catch (error) {
        console.error('Erro ao listar registros de tarefas:', error);
        throw error; // Re-throw para tratar o erro onde for chamado
    }
};

export const saveTarefa = async (tarefa, token) => {
    try {
        const response = await fetch(`${baseUrl}/tarefa/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(tarefa)
        });
        if (!response.ok) {
            throw new Error('Erro ao salvar registro da tarefa:', response.statusText);
        }
        return await response.json();
    } catch (error) {
        console.error('Erro ao salvar registro da tarefa:', error);
        throw error; // Re-throw para tratar o erro onde for chamado
    }
};

export const updateTarefa = async (id, tarefa, token) => {
    try {
        const response = await fetch(`${baseUrl}/tarefa/update/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(tarefa)
        });
        if (!response.ok) {
            throw new Error('Erro ao atualizar registro da tarefa:', response.statusText);
        }
        return await response.json();
    } catch (error) {
        console.error('Erro ao atualizar registro da tarefa:', error);
        throw error; // Re-throw para tratar o erro onde for chamado
    }
};

export const findIdTarefa = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/tarefa/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao encontrar tarefa:', response.statusText);
        }
        if (response.status === 204) {
            return null;
        }
        return await response.json();
    } catch (error) {
        console.error('Erro ao encontrar tarefa:', error);
        throw error; // Re-throw para tratar o erro onde for chamado
    }
};

export default { deleteTarefa, listTarefas, saveTarefa, updateTarefa, findIdTarefa };
