const baseUrl = 'https://ar3biai-d5c7f4185f18.herokuapp.com';

export const deletePJ = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/pessoa-juridica/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        console.error('Erro ao excluir registro da pessoa jurídica:', error);
    }
};

export const listPJ = async (token) => {
    try {
        const response = await fetch(`${baseUrl}/pessoa-juridica/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao listar registros de pessoas jurídicas:', error);
    }
};

export const savePJ = async (pj, token) => {
    try {
        const response = await fetch(`${baseUrl}/pessoa-juridica/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(pj)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao salvar registro da pessoa jurídica:', error);
    }
};

export const updatePJ = async (id, pj, token) => {
    try {
        const response = await fetch(`${baseUrl}/pessoa-juridica/update/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(pj)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao atualizar registro da pessoa jurídica:', error);
    }
};

export const findIdPJ = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/pessoa-juridica/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Erro ao encontrar usuário:', response.statusText);
        }

        if (response.status === 204) {
            return null; 
        }
        
        return response.json();
    } catch (error) {
        console.error('Erro ao encontrar pessoa jurídica:', error);
    }
};

export default { deletePJ, listPJ, savePJ, updatePJ, findIdPJ };
