const baseUrl = 'https://ar3biai-d5c7f4185f18.herokuapp.com';

export const deleteFornecedorFisico = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/fornecedor-fisico/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        console.error('Erro ao excluir registro do fornecedor-fisico:', error);
    }
};

export const listFornecedoresFisico = async (token) => {
    try {
        const response = await fetch(`${baseUrl}/fornecedor-fisico/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao listar registros de fornecedor-fisico:', error);
    }
};

export const saveFornecedorFisico = async (fornecedor, token) => {
    try {
        const response = await fetch(`${baseUrl}/fornecedor-fisico/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fornecedor)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao salvar fornecedor-fisico do fornecedor:', error);
    }
};

export const updateFornecedorFisico = async (id, fornecedor, token) => {
    try {
        const response = await fetch(`${baseUrl}/fornecedor-fisico/update/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fornecedor)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao atualizar registro do fornecedor-fisico:', error);
    }
};

export const findIdFornecedorFisico = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/fornecedor-fisico/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Erro ao encontrar usuário:', response.statusText);
        }

        if (response.status === 204) {
            return null;
        }

        return response.json();
    } catch (error) {
        console.error('Erro ao encontrar FornecedorFisico:', error);
    }
};

export default { deleteFornecedorFisico, listFornecedoresFisico, saveFornecedorFisico, updateFornecedorFisico, findIdFornecedorFisico };
