import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { listContratos, deleteContrato } from '../../services/ContratoCalls.js';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

function ViewContract() {
  const [contratos, setContratos] = useState([]);

  useEffect(() => {
    const fetchContratos = async () => {
      try {
        const token = sessionStorage.getItem('token');

        if (!token) {
          throw new Error('Token de autenticação não encontrado');
        }

        const data = await listContratos(token);
        setContratos(data);
      } catch (error) {
        console.error('Erro ao obter a lista de contratos:', error);
      }
    };
    fetchContratos();
  }, []);

  const handleDeleteContrato = async (id) => {
    try {
      const token = sessionStorage.getItem('token');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      await deleteContrato(id, token);

      const updatedContratos = contratos.filter(contrato => contrato.id !== id);
      setContratos(updatedContratos);
    } catch (error) {
      console.error('Erro ao deletar o contrato:', error);
    }
  };

  const setSessionStorageItem = (pageName, id) => {
    sessionStorage.setItem("pageName", pageName);
    sessionStorage.setItem("id", id);
  };

  return (
    <div style={{ margin: '0 auto', padding: '1rem', width: "100%", maxWidth: '100%', backgroundColor: '#f7f7f7', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', overflowX: 'auto' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse', minWidth: '600px' }}>
        <thead>
          <tr style={{ backgroundColor: '#007bff', color: 'white', textAlign: 'left' }}>
            <th style={{ padding: '0.5rem', minWidth: '100px' }}>Número</th>
            <th style={{ padding: '0.5rem', minWidth: '100px' }}>Identificação</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Tipo de Contrato</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Vigência</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Pregão</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Status do Contrato</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Dispensa</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Vencimento</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Alerta de Pagamento</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Nota Fiscal</th>
            <th style={{ padding: '0.5rem', minWidth: '100px' }}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {contratos.map(contrato => (
            <tr key={contrato.id} style={{ borderBottom: '1px solid #dee2e6' }}>
              <td style={{ padding: '0.5rem' }}>{contrato.numero}</td>
              <td style={{ padding: '0.5rem' }}>{contrato.identificacao}</td>
              <td style={{ padding: '0.5rem' }}>{contrato.tipoContrato}</td>
              <td style={{ padding: '0.5rem' }}>{contrato.vigencia}</td>
              <td style={{ padding: '0.5rem' }}>{contrato.pregao}</td>
              <td style={{ padding: '0.5rem' }}>{contrato.statusContrato}</td>
              <td style={{ padding: '0.5rem' }}>{contrato.dispensa}</td>
              <td style={{ padding: '0.5rem' }}>{contrato.vencimento}</td>
              <td style={{ padding: '0.5rem' }}>{contrato.alertaPagamento}</td>
              <td style={{ padding: '0.5rem' }}>{contrato.notaFiscal}</td>
              <td style={{ padding: '0.5rem' }}>
                <EditIcon onClick={() => setSessionStorageItem("FormContract", contrato.id)} style={{ marginRight: '0.5rem', backgroundColor: '#ffc107', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                  Editar
                </EditIcon>

                <ClearIcon onClick={() => handleDeleteContrato(contrato.id)} style={{ backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                  Deletar
                </ClearIcon>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Link to="/Register/Contract" style={{ textDecoration: 'none' }}>
        <button style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Cadastrar Contrato
        </button>
      </Link>
    </div>
  );
}

export default ViewContract;
