import React, { useState, useEffect } from "react";
import { saveTarefa, updateTarefa, findIdTarefa } from '../../services/TarefaCalls.js';

function FormTask({ id }) {
    const [formData, setFormData] = useState({
        numero: "",
        identificacao: "",
        tipo: "",
        status: "",
        prioridade: "",
        cliente: "",
        responsavel: "",
        dataInicio: "",
        dataTermino: "",
        dataEntrega: "",
        observacoes: ""
    });

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const token = sessionStorage.getItem('token');

    const fetchTarefaData = async () => {
        try {
            const tarefaData = await findIdTarefa(id, token);
            if (tarefaData) {
                setFormData({
                    numero: tarefaData.numero,
                    identificacao: tarefaData.identificacao,
                    tipo: tarefaData.tipo,
                    status: tarefaData.status,
                    prioridade: tarefaData.prioridade,
                    cliente: tarefaData.cliente.map(c => c.nome).join(", "), // Concatena os nomes dos clientes
                    responsavel: tarefaData.responsavel.nome, // Assumindo que 'responsavel' é um objeto com um campo 'nome'
                    dataInicio: tarefaData.dataInicio,
                    dataTermino: tarefaData.dataTermino,
                    dataEntrega: tarefaData.dataEntrega,
                    observacoes: tarefaData.observacoes
                });
            } else {
                console.error("Tarefa não encontrada");
            }
        } catch (error) {
            console.error("Erro ao buscar dados da tarefa:", error);
        }
    };

    useEffect(() => {
        if (id >= 1) {
            fetchTarefaData();
        }
    }, [id, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const taskData = {
                ...formData,
                cliente: formData.cliente.split(", ").map(nome => ({ nome })), // Converte a string de nomes de clientes para objetos
                responsavel: { nome: formData.responsavel } // Converte a string do responsável para um objeto
            };

            if (id >= 1) {
                await updateTarefa(id, taskData, token);
                setPopupMessage("Tarefa atualizada com sucesso");
            } else {
                await saveTarefa(taskData, token);
                setPopupMessage("Tarefa criada com sucesso");
            }
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 3000);
        } catch (error) {
            console.error("Erro ao salvar/atualizar tarefa:", error);
            setPopupMessage("Erro ao salvar/atualizar tarefa");
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 3000);
        }
    };

    return (
        <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input style={{ display: "flex", width: "40%" }} type="text" name="numero" placeholder="Número" value={formData.numero} onChange={handleChange} required />
                    <input style={{ display: "flex", width: "40%" }} type="text" name="identificacao" placeholder="Identificação" value={formData.identificacao} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input type="text" name="tipo" placeholder="Tipo" value={formData.tipo} onChange={handleChange} required />
                    <input type="text" name="status" placeholder="Status" value={formData.status} onChange={handleChange} required />
                    <input type="text" name="prioridade" placeholder="Prioridade" value={formData.prioridade} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input style={{ display: "flex", width: "40%" }} type="text" name="cliente" placeholder="Clientes (separados por vírgula)" value={formData.cliente} onChange={handleChange} required />
                    <input style={{ display: "flex", width: "40%" }} type="text" name="responsavel" placeholder="Responsável" value={formData.responsavel} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input type="date" name="dataInicio" placeholder="Data de Início" value={formData.dataInicio} onChange={handleChange} required />
                    <input type="date" name="dataTermino" placeholder="Data de Término" value={formData.dataTermino} onChange={handleChange} required />
                    <input type="date" name="dataEntrega" placeholder="Data de Entrega" value={formData.dataEntrega} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
                    <input type="text" name="observacoes" placeholder="Observações" value={formData.observacoes} onChange={handleChange} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", margin: "5vh 5vw" }}>
                    <button type="submit">Cadastrar</button>
                </div>
            </form>
            {showPopup && <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#ffffff", padding: "20px", borderRadius: "8px", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)" }}>{popupMessage}</div>}
        </div>
    );
}

export default FormTask;
