import React, { useState } from "react";
import { Link } from "react-router-dom";
import { autenticarUsuario } from "../services/AuthCall.js";
import background from "../resources/background.jpg";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault(); 

    try {
      const data = await autenticarUsuario({ nome: username, senha: password });

      if (data && data.token) {
        window.location.href = `/Home`;
      } else {
        setError("Usuário ou senha incorretos");
      }
    } catch (error) {
      console.error("Erro ao autenticar o usuário:", error);
      setError("Erro ao autenticar o usuário.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        position: "relative",
        zIndex: 1,
      }}
    >
      <img
        src={background}
        alt="Background"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          maxWidth: "400px",
          width: "100%",
          zIndex: 2,
        }}
      >
        <h2 style={{ textAlign: "center", marginBottom: "2rem", color: "#495057" }}>Login</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="username" style={{ marginBottom: ".5rem", color: "#495057" }}>Usuário:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{ width: "100%", padding: ".5rem", border: "1px solid #ced4da", borderRadius: "4px" }}
          />
          <label htmlFor="password" style={{ display: "block", marginBottom: ".5rem", color: "#495057" }}>Senha:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ width: "100%", padding: ".5rem", border: "1px solid #ced4da", borderRadius: "4px" }}
          />
          {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <button
              type="submit"
              style={{
                padding: ".75rem 1.5rem",
                border: "none",
                borderRadius: "4px",
                backgroundColor: "#007bff",
                color: "white",
                cursor: "pointer",
              }}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
