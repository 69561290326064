const baseUrl = 'https://ar3biai-d5c7f4185f18.herokuapp.com';

export const deleteCliente = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/cliente/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Erro ao excluir cliente:', response.statusText);
        }

        return response;
    } catch (error) {
        console.error('Erro ao excluir cliente:', error);
        throw error;
    }
};

export const listClientes = async (token) => {
    try {
        const response = await fetch(`${baseUrl}/cliente/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Erro ao listar clientes:', response.statusText);
        }

        return await response.json();
    } catch (error) {
        console.error('Erro ao listar clientes:', error);
        throw error;
    }
};

export const saveCliente = async (cliente, token) => {
    try {
        const response = await fetch(`${baseUrl}/cliente/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(cliente)
        });

        if (!response.ok) {
            throw new Error('Erro ao salvar cliente:', response.statusText);
        }

        return response.json();
    } catch (error) {
        console.error('Erro ao salvar cliente:', error);
        throw error;
    }
};

export const updateCliente = async (id, cliente, token) => {
    try {
        const response = await fetch(`${baseUrl}/cliente/update/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(cliente)
        });

        if (!response.ok) {
            throw new Error('Erro ao atualizar cliente:', response.statusText);
        }

        return response.json();
    } catch (error) {
        console.error('Erro ao atualizar cliente:', error);
        throw error;
    }
};

export const findIdCliente = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/cliente/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Erro ao encontrar cliente:', response.statusText);
        }

        if (response.status === 204) {
            return null;
        }

        return response.json();
    } catch (error) {
        console.error('Erro ao encontrar cliente:', error);
        throw error;
    }
};

export default { deleteCliente, listClientes, saveCliente, updateCliente, findIdCliente };
