const baseUrl = 'https://ar3biai-d5c7f4185f18.herokuapp.com';

export const autenticarUsuario = async (dadosLogin) => {
    try {
        const response = await fetch(`${baseUrl}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dadosLogin)
        });

        if (!response.ok) {
            throw new Error('Não foi possível autenticar o usuário.');
        }

        const data = await response.json();

        if (data && data.token) {
            sessionStorage.setItem('token', data.token);
        }

        return data;
    } catch (error) {
        console.error('Erro ao autenticar o usuário:', error);
        throw new Error('Erro ao autenticar o usuário');
    }
};
