import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { saveFinanceiro, updateFinanceiro, findIdFinanceiro } from '../../services/FinanceiroCalls.js';

function FormFinancial({ id }) {
    const [formData, setFormData] = useState({
      contrato: "",
      notaFiscal: "",
      identificacao: "",
      tipo: "",
      categoria: "",
      status: "",
      prioridade: "",
      dataCriacao: "",
      recebimentos: "",
      observacoes: ""
    });

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");

    const token = sessionStorage.getItem('token');

    const fetchFinancialData = async () => {
      try {
        const financialData = await findIdFinanceiro(id, token);
        if (financialData) {
          setFormData({
            contrato: financialData.contrato,
            notaFiscal: financialData.notaFiscal,
            identificacao: financialData.identificacao,
            tipo: financialData.tipo,
            categoria: financialData.categoria,
            status: financialData.status,
            prioridade: financialData.prioridade,
            dataCriacao: financialData.dataCriacao,
            recebimentos: financialData.recebimentos,
            observacoes: financialData.observacoes
          });
        }
      } catch (error) {
        console.error("Erro ao buscar dados do registro financeiro:", error);
      }
    };

    useEffect(() => {
        if (id >= 1) {
          fetchFinancialData();
        }
      }, [id]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        if (id >= 1) {
          await updateFinanceiro(id, formData, token);
          setPopupMessage("Registro financeiro atualizado com sucesso");
        } else {
          await saveFinanceiro(formData, token);
          setPopupMessage("Registro financeiro criado com sucesso");
        }
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      } catch (error) {
        console.error("Erro ao salvar/atualizar Registro financeiro:", error);
        setPopupMessage("Erro ao salvar/atualizar Registro financeiro");
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      }
    };

  return (
    <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <input style={{ display: "flex", width: "40%" }} type="text" name="contrato" placeholder="Contrato" value={formData.contrato} onChange={handleChange} required />
          <input style={{ display: "flex", width: "40%" }} type="text" name="identificacao" placeholder="Identificação" value={formData.identificacao} onChange={handleChange} required />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <input type="text" name="tipo" placeholder="Tipo" value={formData.tipo} onChange={handleChange} required />
          <input type="text" name="categoria" placeholder="Categoria" value={formData.categoria} onChange={handleChange} required />
          <input type="text" name="status" placeholder="Status" value={formData.status} onChange={handleChange} required />
          <input type="text" name="prioridade" placeholder="Prioridade" value={formData.prioridade} onChange={handleChange} required />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <input type="date" name="dataCriacao" placeholder="Data de Criação" value={formData.dataCriacao} onChange={handleChange} required />
          <InputMask
            style={{ display: "flex", width: "40%" }}
            mask="9999999999999999.99"
            maskChar={null}
            value={formData.recebimentos}
            onChange={handleChange}
          >
            {() => <input type="text" name="recebimentos" placeholder="Recebimentos" required />}
          </InputMask>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
          <input type="text" name="observacoes" placeholder="Observações" value={formData.observacoes} onChange={handleChange} required />
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
          <input type="text" name="notaFiscal" placeholder="Nota Fiscal" value={formData.notaFiscal} onChange={handleChange} required />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", margin: "5vh 5vw" }}>
          <button type="submit">Cadastrar</button>
        </div>
      </form>
      {showPopup && <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#ffffff", padding: "20px", borderRadius: "8px", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)" }}>{popupMessage}</div>}
    </div>
  );
}

export default FormFinancial;
