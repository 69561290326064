import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { listProjetos, deleteProjeto } from '../../services/ProjetoCalls.js';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { VictoryPie } from 'victory';

function ViewProjects() {
  const [projetos, setProjetos] = useState([]);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const fetchProjetos = async () => {
      try {
        const token = sessionStorage.getItem('token');

        if (!token) {
          throw new Error('Token de autenticação não encontrado');
        }

        const data = await listProjetos(token);
        setProjetos(data);
      } catch (error) {
        console.error('Erro ao obter a lista de Projetos:', error);
      }
    };
    fetchProjetos();
  }, [projetos]);

  const handleDeleteProjeto = async (id) => {
    try {
      const token = sessionStorage.getItem('token');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      await deleteProjeto(id, token);

      const updatedProjetos = projetos.filter(projeto => projeto.id !== id);
      setProjetos(updatedProjetos);
    } catch (error) {
      console.error('Erro ao deletar o projeto:', error);
    }
  };

  const setSessionStorageItem = (pageName, id) => {
    sessionStorage.setItem("pageName", pageName);
    sessionStorage.setItem("id", id);
  };

  // Prepara dados para o gráfico de status dos projetos
  const statusProjetoData = projetos.reduce((acc, projeto) => {
    const status = projeto.status;
    if (!acc[status]) {
      acc[status] = 0;
    }
    acc[status]++;
    return acc;
  }, {});

  const chartData = Object.keys(statusProjetoData).map(status => ({
    x: status,
    y: statusProjetoData[status],
    label: `${status}: ${statusProjetoData[status]}`
  }));

  return (
    <div style={{ margin: '0 auto', padding: '1rem', width: "80%", maxWidth: '100%', overflowX: 'auto', backgroundColor: '#f7f7f7', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <button onClick={() => setShowChart(!showChart)} style={{ marginTop: '1rem', marginBottom: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
        {showChart ? 'Mostrar Tabela' : 'Mostrar Gráfico'}
      </button>
      {showChart ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60vh', marginTop: '2rem' }}>
        <div style={{ width: '100%', maxWidth: '800px' }}>
          <h3 style={{ textAlign: 'center' }}>Distribuição de Projetos por Status</h3>
          <VictoryPie
                    data={chartData}
                    colorScale={["tomato", "orange", "gold", "cyan", "navy"]}
                    style={{ labels: { fill: "white", fontSize: 12, fontWeight: "bold" } }}
                    labelRadius={100}
                  />
        </div>
      </div>
      ) : (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#007bff', color: 'white', textAlign: 'left' }}>
              <th style={{ padding: '0.5rem' }}>ID</th>
              <th style={{ padding: '0.5rem' }}>Número</th>
              <th style={{ padding: '0.5rem' }}>Identificação</th>
              <th style={{ padding: '0.5rem' }}>Responsável</th>
              <th style={{ padding: '0.5rem' }}>Funcionários</th>
              <th style={{ padding: '0.5rem' }}>Contratação Externa</th>
              <th style={{ padding: '0.5rem' }}>Serviços</th>
              <th style={{ padding: '0.5rem' }}>Tipo</th>
              <th style={{ padding: '0.5rem' }}>Categoria</th>
              <th style={{ padding: '0.5rem' }}>Status</th>
              <th style={{ padding: '0.5rem' }}>Prioridade</th>
              <th style={{ padding: '0.5rem' }}>Cliente</th>
              <th style={{ padding: '0.5rem' }}>Data de Início</th>
              <th style={{ padding: '0.5rem' }}>Data de Término</th>
              <th style={{ padding: '0.5rem' }}>Data de Entrega</th>
              <th style={{ padding: '0.5rem' }}>Desempenho</th>
              <th style={{ padding: '0.5rem' }}>Custo</th>
              <th style={{ padding: '0.5rem' }}>Rendimento</th>
              <th style={{ padding: '0.5rem' }}>Investido</th>
              <th style={{ padding: '0.5rem' }}>Observações</th>
              <th style={{ padding: '0.5rem' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {projetos.map(projeto => (
              <tr key={projeto.id} style={{ borderBottom: '1px solid #dee2e6' }}>
                <td style={{ padding: '0.5rem' }}>{projeto.id}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.numero}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.identificacao}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.responsavel}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.funcionarios.map(funcionario => funcionario.nome).join(', ')}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.contratacaoExterna.map(contrato => contrato.numeroContrato).join(', ')}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.servicos}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.tipo}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.categoria}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.status}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.prioridade}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.cliente.map(cliente => cliente.nome).join(', ')}</td>
                <td style={{ padding: '0.5rem' }}>{new Date(projeto.dataInicio).toLocaleDateString()}</td>
                <td style={{ padding: '0.5rem' }}>{new Date(projeto.dataTermino).toLocaleDateString()}</td>
                <td style={{ padding: '0.5rem' }}>{new Date(projeto.dataEntrega).toLocaleDateString()}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.desempenho}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.custo}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.rendimento}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.investido}</td>
                <td style={{ padding: '0.5rem' }}>{projeto.observacoes}</td>
                <td style={{ padding: '0.5rem' }}>
                  <EditIcon onClick={() => setSessionStorageItem("FormProject", projeto.id)} style={{ marginRight: '0.5rem', backgroundColor: '#ffc107', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Editar
                  </EditIcon>
                  <ClearIcon onClick={() => handleDeleteProjeto(projeto.id)} style={{ backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Deletar
                  </ClearIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Link to="/Register/Project" style={{ textDecoration: 'none' }}>
        <button type="button" style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Cadastrar Projeto
        </button>
      </Link>
    </div>
  );
}

export default ViewProjects;
