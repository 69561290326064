import { useState, useEffect } from "react";
import { findIdUsuario, updateUsuario, saveUsuario } from '../../services/UsuarioCalls.js';

function FormUser({ id }) {
    const [formData, setFormData] = useState({
      nome: "",
      nascimento: "",
      senha: "",
      email: "",
      detalhes: "",
      contato: "",
      endereco: "",
      role: "CLIENTE",
    });

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");

    const token = sessionStorage.getItem('token');

    const fetchUserData = async () => {
      try {
        const userData = await findIdUsuario(id, token);
        if (userData) {
          setFormData({
            nome: userData.nome,
            nascimento: userData.nascimento,
            senha: userData.senha,
            email: userData.email,
            detalhes: userData.detalhes,
            contato: userData.contato,
            endereco: userData.endereco,
            role: userData.role,
          });
        }
      } catch (error) {
        console.error("Erro ao buscar dados do usuário:", error);
      }
    };
  
    useEffect(() => {
        if (id >= 1) {
          fetchUserData();
        }
      }, [id]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        if (id >= 1) {
          await updateUsuario(id, formData, token);
          setPopupMessage("Usuário atualizado com sucesso");
        } else {
          console.log("formData", formData);
          await saveUsuario(formData, token);
          setPopupMessage("Usuário criado com sucesso");
        }
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      } catch (error) {
        console.error("Erro ao salvar/atualizar usuário:", error);
        setPopupMessage("Erro ao salvar/atualizar usuário");
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      }
    };

  return (
    <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <input style={{ display: "flex", width: "40%" }} type="text" name="nome" placeholder="Nome" value={formData.nome} onChange={handleChange} required />
          <input style={{ display: "flex", width: "40%" }} type="password" name="senha" placeholder="Senha" value={formData.senha} onChange={handleChange} required />
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
          <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <input type="text" name="nascimento" placeholder="Data de Nascimento" value={formData.nascimento} onChange={handleChange} required />
          <input style={{ display: "flex", width: "40%" }} type="text" name="contato" placeholder="Contato" value={formData.contato} onChange={handleChange} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
          <input type="text" name="endereco" placeholder="Endereço" value={formData.endereco} onChange={handleChange} required />
        </div>

        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "flex-end", margin: "5vh 5vw" }}>
          <select name="role" value={formData.role} onChange={handleChange}>
            <option value="CLIENTE">CLIENTE</option>
            <option value="ADIMINISTRADOR">ADIMINISTRADOR</option>
            <option value="USUARIO_INTERNO">USUARIO INTERNO</option>
          </select>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", margin: "5vh 5vw" }}>
          <button type="submit">Cadastrar</button>
        </div>
      </form>
      {showPopup && <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#ffffff", padding: "20px", borderRadius: "8px", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)" }}>{popupMessage}</div>}
    </div>
  );
}

export default FormUser;
