import React, { useState, useEffect } from "react";
import { savePF, updatePF, findIdPF } from '../../services/PessoaFisicaCalls.js';
import { savePJ, updatePJ, findIdPJ } from '../../services/PessoaJuridicaCalls.js';

function FormPessoa({ id }) {
  const [isPessoaFisica, setIsPessoaFisica] = useState(true);
  const [formData, setFormData] = useState({
    nome: "",
    nascimento: "",
    cpf: "",
    cnpj: "",
    senha: "",
    email: "",
    detalhes: "",
    contato: "",
    endereco: "",
    role: "CLIENTE",
  });

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const token = sessionStorage.getItem('token');

  const fetchData = async () => {
    try {
      let data;
      if (isPessoaFisica) {
        data = await findIdPF(id, token);
      } else {
        data = await findIdPJ(id, token);
      }

      if (data) {
        setFormData({
          nome: data.nome,
          cpf: data.cpf,
          cnpj: data.cnpj,
          nascimento: data.nascimento,
          senha: data.senha,
          email: data.email,
          detalhes: data.detalhes,
          contato: data.contato,
          endereco: data.endereco,
          role: data.role,
        });
      } else {
        console.error("Registro não encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar dados do registro:", error);
    }
  };

  useEffect(() => {
    if (id >= 1) {
      fetchData();
    }
  }, [id, isPessoaFisica]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id >= 1) {
        if (isPessoaFisica) {
          await updatePF(id, formData, token);
        } else {
          await updatePJ(id, formData, token);
        }
        setPopupMessage("Registro atualizado com sucesso");
      } else {
        if (isPessoaFisica) {
          await savePF(formData, token);
        } else {
          await savePJ(formData, token);
        }
        setPopupMessage("Registro criado com sucesso");
      }
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    } catch (error) {
      console.error("Erro ao salvar/atualizar registro:", error);
      setPopupMessage("Erro ao salvar/atualizar registro");
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    }
  };

  return (
    <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <button onClick={() => setIsPessoaFisica(true)}>Pessoa Física</button>
          <button onClick={() => setIsPessoaFisica(false)}>Pessoa Jurídica</button>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <input type="text" name="nome" placeholder="Nome" value={formData.nome} onChange={handleChange} required />
          <input type="password" name="senha" placeholder="Senha" value={formData.senha} onChange={handleChange} required />
          {isPessoaFisica ? (
            <>
              <input type="text" name="cpf" placeholder="CPF" value={formData.cpf} onChange={handleChange} required />
              <input type="text" name="nascimento" placeholder="Data de Nascimento" value={formData.nascimento} onChange={handleChange} required />
            </>
          ) : (
            <>
              <input type="text" name="cnpj" placeholder="CNPJ" value={formData.cnpj} onChange={handleChange} required />
            </>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <input style={{ display: "flex", width: "40%" }} type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
          <input style={{ display: "flex", width: "40%" }} type="text" name="contato" placeholder="Contato" value={formData.contato} onChange={handleChange} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
          <input type="text" name="endereco" placeholder="Endereço" value={formData.endereco} onChange={handleChange} required />
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
          <input type="text" name="detalhes" placeholder="Detalhes" value={formData.detalhes} onChange={handleChange} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", margin: "5vh 5vw" }}>
          <button type="submit">Cadastrar</button>
        </div>
      </form>
      {showPopup && <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#ffffff", padding: "20px", borderRadius: "8px", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)" }}>{popupMessage}</div>}
    </div>
  );
}

export default FormPessoa;
