import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Erro404 from "./Pages/Erro404";
import PrivateRoutes from "./PrivateRoutes";

function isAuthenticated() {
    return sessionStorage.getItem('token') !== null;
  }

function App() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route element={<PrivateRoutes />}>
                        <Route element={<Home />} path="/Home" exact/>
                    </Route>
                    <Route path="*" element={<Erro404 />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
