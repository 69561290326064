import "../App.css";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Navybar from "../components/Navybar";
import Sidebar from "../components/Sidebar";
import MainScreen from "../components/MainScreen";
import Button from "@mui/material/Button";

function Home() {
  const { id } = useParams();
  return (
    <>
    <div style={{display: "flex",height: "100vh", width: "100vw" }}>
      <Sidebar />
      <MainScreen />
    </div>
    </>
  );
}
export default Home;
