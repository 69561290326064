const baseUrl = 'https://ar3biai-d5c7f4185f18.herokuapp.com';

export const deleteFornecedorJuridico = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/fornecedor-juridico/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        console.error('Erro ao excluir registro do fornecedor-juridico:', error);
    }
};

export const listFornecedoresJuridico = async (token) => {
    try {
        const response = await fetch(`${baseUrl}/fornecedor-juridico/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao listar registros de fornecedor-juridico:', error);
    }
};

export const saveFornecedorJuridico = async (fornecedor, token) => {
    try {
        const response = await fetch(`${baseUrl}/fornecedor-juridico/save`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fornecedor)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao salvar registro do fornecedor-juridico:', error);
    }
};

export const updateFornecedorJuridico = async (id, fornecedor, token) => {
    try {
        const response = await fetch(`${baseUrl}/fornecedor-juridico/update/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fornecedor)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao atualizar registro do fornecedor-juridico:', error);
    }
};

export const findIdFornecedorJuridico = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/fornecedor-juridico/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Erro ao encontrar usuário:', response.statusText);
        }

        if (response.status === 204) {
            return null;
        }

        return response.json();
    } catch (error) {
        console.error('Erro ao encontrar FornecedorJuridico:', error);
    }
};

export default { deleteFornecedorJuridico, listFornecedoresJuridico, saveFornecedorJuridico, updateFornecedorJuridico, findIdFornecedorJuridico };
