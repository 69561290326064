import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { listTarefas, deleteTarefa } from '../../services/TarefaCalls.js';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

function ViewTask() {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const token = sessionStorage.getItem('token');

        if (!token) {
          throw new Error('Token de autenticação não encontrado');
        }

        const data = await listTarefas(token);
        setTasks(data);
      } catch (error) {
        console.error('Erro ao obter a lista de tarefas:', error);
      }
    };
    fetchTasks();
  }, []);

  const handleDeleteTask = async (id) => {
    try {
      const token = sessionStorage.getItem('token');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      await deleteTarefa(id, token);

      const updatedTasks = tasks.filter(task => task.id !== id);
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Erro ao deletar a tarefa:', error);
    }
  };

  const setSessionStorageItem = (pageName, id) => {
    sessionStorage.setItem("pageName", pageName);
    sessionStorage.setItem("id", id);
  };

  return (
    <div style={{ margin: '0 auto', padding: '1rem', width: "100%", maxWidth: '100%', backgroundColor: '#f7f7f7', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', overflowX: 'auto' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse', minWidth: '600px' }}>
        <thead>
          <tr style={{ backgroundColor: '#007bff', color: 'white', textAlign: 'left' }}>
            <th style={{ padding: '0.5rem', minWidth: '100px' }}>Número</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Identificação</th>
            <th style={{ padding: '0.5rem', minWidth: '100px' }}>Tipo</th>
            <th style={{ padding: '0.5rem', minWidth: '100px' }}>Status</th>
            <th style={{ padding: '0.5rem', minWidth: '100px' }}>Prioridade</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Cliente</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Responsável</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Data de Início</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Data de Término</th>
            <th style={{ padding: '0.5rem', minWidth: '150px' }}>Data de Entrega</th>
            <th style={{ padding: '0.5rem', minWidth: '200px' }}>Observações</th>
            <th style={{ padding: '0.5rem', minWidth: '100px' }}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map(task => (
            <tr key={task.id} style={{ borderBottom: '1px solid #dee2e6' }}>
              <td style={{ padding: '0.5rem' }}>{task.numero}</td>
              <td style={{ padding: '0.5rem' }}>{task.identificacao}</td>
              <td style={{ padding: '0.5rem' }}>{task.tipo}</td>
              <td style={{ padding: '0.5rem' }}>{task.status}</td>
              <td style={{ padding: '0.5rem' }}>{task.prioridade}</td>
              <td style={{ padding: '0.5rem' }}>{task.cliente.map(c => c.nome).join(", ")}</td>
              <td style={{ padding: '0.5rem' }}>{task.responsavel.nome}</td>
              <td style={{ padding: '0.5rem' }}>{new Date(task.dataInicio).toLocaleDateString()}</td>
              <td style={{ padding: '0.5rem' }}>{new Date(task.dataTermino).toLocaleDateString()}</td>
              <td style={{ padding: '0.5rem' }}>{new Date(task.dataEntrega).toLocaleDateString()}</td>
              <td style={{ padding: '0.5rem' }}>{task.observacoes}</td>
              <td style={{ padding: '0.5rem' }}>
                <EditIcon onClick={() => setSessionStorageItem("FormTask", task.id)} style={{ marginRight: '0.5rem', backgroundColor: '#ffc107', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                  Editar
                </EditIcon>

                <ClearIcon onClick={() => handleDeleteTask(task.id)} style={{ backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                  Deletar
                </ClearIcon>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Link to="/Register/Task" style={{ textDecoration: 'none' }}>
        <button style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Cadastrar Tarefa
        </button>
      </Link>
    </div>
  );
}

export default ViewTask;
