import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { listFornecedoresFisico, deleteFornecedorFisico } from '../../services/FornecedorFisicoCalls.js';
import { listFornecedoresJuridico, deleteFornecedorJuridico } from '../../services/FornecedorJuridicoCalls.js';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { VictoryPie } from 'victory';

function ViewFornecedor() {
  const [fornecedores, setFornecedores] = useState([]);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const fetchFornecedores = async () => {
      try {
        const token = sessionStorage.getItem('token');

        if (!token) {
          throw new Error('Token de autenticação não encontrado');
        }

        const dataPF = await listFornecedoresFisico(token);
        const dataPJ = await listFornecedoresJuridico(token);

        const fornecedoresFisicos = dataPF.map(fornecedor => ({ ...fornecedor, tipoFornecedor: 'Fornecedor Físico' }));
        const fornecedoresJuridicos = dataPJ.map(fornecedor => ({ ...fornecedor, tipoFornecedor: 'Fornecedor Jurídico' }));
        
        setFornecedores([...fornecedoresFisicos, ...fornecedoresJuridicos]);
      } catch (error) {
        console.error('Erro ao obter a lista de fornecedores:', error);
      }
    };
    fetchFornecedores();
  }, []);

  const handleDeleteFornecedor = async (id, tipoFornecedor) => {
    try {
      const token = sessionStorage.getItem('token');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      if (tipoFornecedor === 'Fornecedor Físico') {
        await deleteFornecedorFisico(id, token);
      } else if (tipoFornecedor === 'Fornecedor Jurídico') {
        await deleteFornecedorJuridico(id, token);
      }
      
      const updatedFornecedores = fornecedores.filter(fornecedor => fornecedor.id !== id);
      setFornecedores(updatedFornecedores);
    } catch (error) {
      console.error('Erro ao deletar o fornecedor:', error);
    }
  };

  const setSessionStorageItem = (pageName, id) => {
    sessionStorage.setItem("pageName", pageName);
    sessionStorage.setItem("id", id);
  };

  // Prepara dados para o gráfico de tipos de fornecedores
  const tipoFornecedorData = fornecedores.reduce((acc, fornecedor) => {
    const tipo = fornecedor.servico;
    if (!acc[tipo]) {
      acc[tipo] = 0;
    }
    acc[tipo]++;
    return acc;
  }, {});

  const chartData = Object.keys(tipoFornecedorData).map(tipo => ({
    x: tipo,
    y: tipoFornecedorData[tipo],
    label: `${tipo}: ${tipoFornecedorData[tipo]}`
  }));

  return (
    <div style={{ margin: '0 auto', padding: '1rem', width: "80%", maxWidth: '100%', overflowX: 'auto', backgroundColor: '#f7f7f7', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <button onClick={() => setShowChart(!showChart)} style={{ marginTop: '1rem', marginBottom: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
        {showChart ? 'Mostrar Tabela' : 'Mostrar Gráfico'}
      </button>
      {showChart ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60vh', marginTop: '2rem' }}>
        <div style={{ width: '100%', maxWidth: '800px' }}>
          <h3 style={{ textAlign: 'center' }}>Distribuição de Fornecedores por Serviço</h3>
          <VictoryPie
                    data={chartData}
                    colorScale={["tomato", "orange", "gold", "cyan", "navy"]}
                    style={{ labels: { fill: "white", fontSize: 12, fontWeight: "bold" } }}
                    labelRadius={100}
                  />
        </div>
      </div>
      ) : (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#007bff', color: 'white', textAlign: 'left' }}>
              <th style={{ padding: '0.5rem' }}>ID</th>
              <th style={{ padding: '0.5rem' }}>Nome</th>
              <th style={{ padding: '0.5rem' }}>Nascimento</th>
              <th style={{ padding: '0.5rem' }}>Senha</th>
              <th style={{ padding: '0.5rem' }}>Email</th>
              <th style={{ padding: '0.5rem' }}>Contato</th>
              <th style={{ padding: '0.5rem' }}>Endereço</th>
              <th style={{ padding: '0.5rem' }}>Role</th>
              <th style={{ padding: '0.5rem' }}>CPF/CNPJ</th>
              <th style={{ padding: '0.5rem' }}>Razão Social</th>
              <th style={{ padding: '0.5rem' }}>Serviço</th>
              <th style={{ padding: '0.5rem' }}>Parcelas</th>
              <th style={{ padding: '0.5rem' }}>Cobrança</th>
              <th style={{ padding: '0.5rem' }}>Data de Pagamento</th>
              <th style={{ padding: '0.5rem' }}>Alerta de Pagamento</th>
              <th style={{ padding: '0.5rem' }}>Detalhes</th>
              <th style={{ padding: '0.5rem' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {fornecedores.map(fornecedor => (
              <tr key={fornecedor.id} style={{ borderBottom: '1px solid #dee2e6' }}>
                <td style={{ padding: '0.5rem' }}>{fornecedor.id}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.nome}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.nascimento}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.senha}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.email}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.contato}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.endereco}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.role}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.tipoFornecedor === 'Fornecedor Físico' ? fornecedor.cpf : fornecedor.cnpj}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.razaoSocial}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.servico}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.parcelas}</td>
                <td style={{ padding: '0.5rem' }}>{new Date(fornecedor.cobranca).toLocaleDateString()}</td>
                <td style={{ padding: '0.5rem' }}>{new Date(fornecedor.dataPagamento).toLocaleDateString()}</td>
                <td style={{ padding: '0.5rem' }}>{new Date(fornecedor.alertaPagamento).toLocaleDateString()}</td>
                <td style={{ padding: '0.5rem' }}>{fornecedor.detalhes}</td>
                <td style={{ padding: '0.5rem' }}>
                  <EditIcon onClick={() => setSessionStorageItem("FormFornecedor", fornecedor.id)} style={{ marginRight: '0.5rem', backgroundColor: '#ffc107', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Editar
                  </EditIcon>
                  <ClearIcon onClick={() => handleDeleteFornecedor(fornecedor.id, fornecedor.tipoFornecedor)} style={{ backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Deletar
                  </ClearIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Link to="/Register/Supplay" style={{ textDecoration: 'none' }}>
        <button type="button" style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Cadastrar Fornecedor
        </button>
      </Link>
    </div>
  );
}

export default ViewFornecedor;
