const baseUrl = 'https://ar3biai-d5c7f4185f18.herokuapp.com';

export const deleteProjeto = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/projeto/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        console.error('Erro ao excluir registro do projeto:', error);
    }
};

export const listProjetos = async (token) => {
    try {
        const response = await fetch(`${baseUrl}/projeto/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao listar registros de projetos:', error);
    }
};

export const saveProjeto = async (projeto, token) => {
    try {
        const response = await fetch(`${baseUrl}/projeto/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(projeto)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao salvar registro do projeto:', error);
    }
};

export const updateProjeto = async (id, projeto, token) => {
    try {
        const response = await fetch(`${baseUrl}/projeto/update/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(projeto)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao atualizar registro do projeto:', error);
    }
};

export const findIdProjeto = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/projeto/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Erro ao encontrar usuário:', response.statusText);
        }

        if (response.status === 204) {
            return null;
        }
        
        return response.json();
    } catch (error) {
        console.error('Erro ao encontrar projeto:', error);
    }
};

export default { deleteProjeto, listProjetos, saveProjeto, updateProjeto, findIdProjeto };
