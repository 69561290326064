import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { listFinanceiros, deleteFinanceiro } from '../../services/FinanceiroCalls.js';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTheme } from 'victory';

function ViewFinancial() {
  const [financeiro, setFinanceiro] = useState([]);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const fetchFinanceiro = async () => {
      try {
        const token = sessionStorage.getItem('token');

        if (!token) {
          throw new Error('Token de autenticação não encontrado');
        }

        const data = await listFinanceiros(token);
        setFinanceiro(data);
      } catch (error) {
        console.error('Erro ao obter a lista de Finanças:', error);
      }
    };
    fetchFinanceiro();
  }, []);

  const handleDeleteFinanceiro = async (id) => {
    try {
      const token = sessionStorage.getItem('token');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      await deleteFinanceiro(id, token);

      const updatedFinanceiro = financeiro.filter(item => item.id !== id);
      setFinanceiro(updatedFinanceiro);
    } catch (error) {
      console.error('Erro ao deletar o financeiro:', error);
    }
  };

  const setSessionStorageItem = (pageName, id) => {
    sessionStorage.setItem("pageName", pageName);
    sessionStorage.setItem("id", id);
  };

  const chartData = financeiro.map(item => ({
    x: new Date(item.dataCriacao),
    y: parseFloat(item.recebimentos),
  }));

  return (
    <div style={{ margin: '0 auto', padding: '1rem', width: "80%", maxWidth: '100%', overflowX: 'auto', backgroundColor: '#f7f7f7', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <button onClick={() => setShowChart(!showChart)} style={{ marginTop: '1rem', marginBottom: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
        {showChart ? 'Mostrar Tabela' : 'Mostrar Gráfico'}
      </button>
      {showChart ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60vh', marginTop: '2rem' }}>
          <div style={{ width: '100%', maxWidth: '800px' }}>
            <h3 style={{ textAlign: 'center' }}>Valores Financeiros ao Longo do Tempo</h3>
            <VictoryChart
              theme={VictoryTheme.material}
              scale={{ x: "time" }}
            >
              <VictoryAxis
                tickFormat={(t) => `${t.getDate()}/${t.getMonth() + 1}/${t.getFullYear()}`}
                style={{ tickLabels: { fontSize: 10, angle: -45, textAnchor: 'end' } }}
              />
              <VictoryAxis
                dependentAxis
                tickFormat={(x) => `R$ ${x}`}
              />
              <VictoryLine
                data={chartData}
                style={{
                  data: { stroke: "#007bff" },
                  parent: { border: "1px solid #ccc" }
                }}
              />
            </VictoryChart>
          </div>
        </div>
      ) : (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#007bff', color: 'white', textAlign: 'left' }}>
              <th style={{ padding: '0.5rem' }}>ID</th>
              <th style={{ padding: '0.5rem' }}>Contrato</th>
              <th style={{ padding: '0.5rem' }}>Nota Fiscal</th>
              <th style={{ padding: '0.5rem' }}>Identificação</th>
              <th style={{ padding: '0.5rem' }}>Tipo</th>
              <th style={{ padding: '0.5rem' }}>Categoria</th>
              <th style={{ padding: '0.5rem' }}>Status</th>
              <th style={{ padding: '0.5rem' }}>Prioridade</th>
              <th style={{ padding: '0.5rem' }}>Data de Criação</th>
              <th style={{ padding: '0.5rem' }}>Recebimentos</th>
              <th style={{ padding: '0.5rem' }}>Observações</th>
              <th style={{ padding: '0.5rem' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {financeiro.map(item => (
              <tr key={item.id} style={{ borderBottom: '1px solid #dee2e6' }}>
                <td style={{ padding: '0.5rem' }}>{item.id}</td>
                <td style={{ padding: '0.5rem' }}>{item.contrato}</td>
                <td style={{ padding: '0.5rem' }}>{item.notaFiscal}</td>
                <td style={{ padding: '0.5rem' }}>{item.identificacao}</td>
                <td style={{ padding: '0.5rem' }}>{item.tipo}</td>
                <td style={{ padding: '0.5rem' }}>{item.categoria}</td>
                <td style={{ padding: '0.5rem' }}>{item.status}</td>
                <td style={{ padding: '0.5rem' }}>{item.prioridade}</td>
                <td style={{ padding: '0.5rem' }}>{new Date(item.dataCriacao).toLocaleDateString()}</td>
                <td style={{ padding: '0.5rem' }}>{item.recebimentos}</td>
                <td style={{ padding: '0.5rem' }}>{item.observacoes}</td>
                <td style={{ padding: '0.5rem' }}>
                  <EditIcon onClick={() => setSessionStorageItem("FormFinancial", item.id)} style={{ marginRight: '0.5rem', backgroundColor: '#ffc107', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Editar
                  </EditIcon>
                  <ClearIcon onClick={() => handleDeleteFinanceiro(item.id)} style={{ backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Deletar
                  </ClearIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Link to="/Register/Financial" style={{ textDecoration: 'none' }}>
        <button type="button" style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Cadastrar Financeiro
        </button>
      </Link>
    </div>
  );
}

export default ViewFinancial;

