import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { listUsuarios, deleteUsuario } from '../../services/UsuarioCalls.js';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { VictoryPie } from 'victory';

function ViewUser() {
  const [usuarios, setUsuarios] = useState([]);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const token = sessionStorage.getItem('token');

        if (!token) {
          throw new Error('Token de autenticação não encontrado');
        }

        const data = await listUsuarios(token);
        console.log('Dados dos usuários:', data); // Log para depuração
        if (Array.isArray(data)) {
          setUsuarios(data);
        } else {
          throw new Error('Dados retornados não são uma lista');
        }
      } catch (error) {
        console.error('Erro ao obter a lista de usuários:', error);
        setUsuarios([]); // Define usuários como uma lista vazia em caso de erro
      }
    };
    fetchUsuarios();
  }, []);

  const handleDeleteUsuario = async (id) => {
    try {
      const token = sessionStorage.getItem('token');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      await deleteUsuario(id, token);

      const updatedUsuarios = usuarios.filter(usuario => usuario.id !== id);
      setUsuarios(updatedUsuarios);
    } catch (error) {
      console.error('Erro ao deletar o usuário:', error);
    }
  };

  const setSessionStorageItem = (pageName, id) => {
    sessionStorage.setItem("pageName", pageName);
    sessionStorage.setItem("id", id);
  };

  const roleData = Array.isArray(usuarios) ? usuarios.reduce((acc, usuario) => {
    const role = usuario.role;
    if (!acc[role]) {
      acc[role] = 0;
    }
    acc[role]++;
    return acc;
  }, {}) : {};

  const chartData = Object.keys(roleData).map(role => ({
    x: role,
    y: roleData[role],
    label: `${role}: ${roleData[role]}`
  }));

  return (
    <div style={{ margin: '0 auto', padding: '1rem', width: "100%", maxWidth: '100%', backgroundColor: '#f7f7f7', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', overflowX: 'auto' }}>
      <button onClick={() => setShowChart(!showChart)} style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
        {showChart ? 'Mostrar Tabela' : 'Mostrar Gráfico'}
      </button>
      {showChart ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60vh', marginTop: '2rem' }}>
          <div style={{ width: '100%', maxWidth: '800px' }}>
            <h3 style={{ textAlign: 'center' }}>Distribuição de Usuários por Role</h3>
            <VictoryPie
              data={chartData}
              colorScale={["tomato", "orange", "gold", "cyan", "navy" ]}
              style={{ labels: { fill: "black", fontSize: 12, fontWeight: "bold" } }}
              labelRadius={100}
            />
          </div>
        </div>
      ) : (
        <table style={{ width: '100%', borderCollapse: 'collapse', minWidth: '600px' }}>
          <thead>
            <tr style={{ backgroundColor: '#007bff', color: 'white', textAlign: 'left' }}>
              <th style={{ padding: '0.5rem', minWidth: '100px' }}>ID</th>
              <th style={{ padding: '0.5rem', minWidth: '100px' }}>Nome</th>
              <th style={{ padding: '0.5rem', minWidth: '150px' }}>Data de Nascimento</th>
              <th style={{ padding: '0.5rem', minWidth: '100px' }}>Senha</th>
              <th style={{ padding: '0.5rem', minWidth: '200px' }}>Email</th>
              <th style={{ padding: '0.5rem', minWidth: '100px' }}>Contato</th>
              <th style={{ padding: '0.5rem', minWidth: '200px' }}>Endereço</th>
              <th style={{ padding: '0.5rem', minWidth: '100px' }}>Role</th>
              <th style={{ padding: '0.5rem', minWidth: '100px' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map(usuario => (
              <tr key={usuario.id} style={{ borderBottom: '1px solid #dee2e6' }}>
                <td style={{ padding: '0.5rem' }}>{usuario.id}</td>
                <td style={{ padding: '0.5rem' }}>{usuario.nome}</td>
                <td style={{ padding: '0.5rem' }}>{usuario.nascimento}</td>
                <td style={{ padding: '0.5rem' }}>{usuario.senha}</td>
                <td style={{ padding: '0.5rem' }}>{usuario.email}</td>
                <td style={{ padding: '0.5rem' }}>{usuario.contato}</td>
                <td style={{ padding: '0.5rem' }}>{usuario.endereco}</td>
                <td style={{ padding: '0.5rem' }}>{usuario.role}</td>
                <td style={{ padding: '0.5rem' }}>
                  <EditIcon onClick={() => setSessionStorageItem("FormUser", usuario.id)} style={{ marginRight: '0.5rem', backgroundColor: '#ffc107', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Editar
                  </EditIcon>

                  <ClearIcon onClick={() => handleDeleteUsuario(usuario.id)} style={{ backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Deletar
                  </ClearIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Link to="/Register/User" style={{ textDecoration: 'none' }}>
        <button style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Cadastrar Usuário
        </button>
      </Link>
    </div>
  );
}

export default ViewUser;
