import React, { useState, useEffect } from "react";
import { saveFornecedorFisico, updateFornecedorFisico, findIdFornecedorFisico } from '../../services/FornecedorFisicoCalls.js';
import { saveFornecedorJuridico, updateFornecedorJuridico, findIdFornecedorJuridico } from '../../services/FornecedorJuridicoCalls.js';

function FormFornecedor({ id }) {
  const [isFornecedorFisico, setIsFornecedorFisico] = useState(true);
  const [formData, setFormData] = useState({
    nome: "",
    nascimento: "",
    cpf: "",
    cnpj: "",
    senha: "",
    email: "",
    detalhes: "",
    contato: "",
    endereco: "",
    role: "CLIENTE",
    razaoSocial: "",
    servico: "",
    parcelas: 0,
    cobranca: "",
    dataPagamento: "",
    alertaPagamento: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const token = sessionStorage.getItem('token');


    const fetchData = async () => {
      try {
        let data;
        if (isFornecedorFisico) {
          data = await findIdFornecedorFisico(id, token);
        } else {
          data = await findIdFornecedorJuridico(id, token);
        }

        if (data) {
          setFormData({
            nome: data.nome,
            nascimento: data.nascimento,
            cpf: data.cpf,
            cnpj: data.cnpj,
            senha: data.senha,
            email: data.email,
            detalhes: data.detalhes,
            contato: data.contato,
            endereco: data.endereco,
            role: data.role, 
            razaoSocial: data.razaoSocial,
            servico: data.servico,
            parcelas: data.parcelas,
            cobranca: data.cobranca,
            dataPagamento: data.dataPagamento,
            alertaPagamento: data.alertaPagamento,
          });
        } 
      } catch (error) {
        console.error("Erro ao buscar dados do registro:", error);
      }
    };

    useEffect(() => {
      if (id >= 1) {
        fetchData();
      }
    }, [id, isFornecedorFisico]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id >= 1) {
        if (isFornecedorFisico) {
          await updateFornecedorFisico(id, formData, token);
        } else {
          await updateFornecedorJuridico(id, formData, token);
        }
        setPopupMessage("Registro atualizado com sucesso");
      } else {
        if (isFornecedorFisico) {
          await saveFornecedorFisico(formData, token);
        } else {
          await saveFornecedorJuridico(formData, token);
        }
        setPopupMessage("Registro criado com sucesso");
      }
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    } catch (error) {
      console.error("Erro ao salvar/atualizar Registro:", error);
      setPopupMessage("Erro ao salvar/atualizar Registro");
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    }
  };


  return (
    <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <button onClick={() => setIsFornecedorFisico(true)}>Fornecedor Físico</button>
          <button onClick={() => setIsFornecedorFisico(false)}>Fornecedor Jurídico</button>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <input type="text" name="nome" placeholder="Nome" value={formData.nome} onChange={handleChange} required />
          <input type="password" name="senha" placeholder="Senha" value={formData.senha} onChange={handleChange} required />
          {isFornecedorFisico ? (
            <>
              <input type="text" name="cpf" placeholder="CPF" value={formData.cpf} onChange={handleChange} required />
              <input type="text" name="nascimento" placeholder="Data de Nascimento" value={formData.nascimento} onChange={handleChange} required />
            </>
          ) : (
            <>
              <input type="text" name="cnpj" placeholder="CNPJ" value={formData.cnpj} onChange={handleChange} required />
            </>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <input style={{ display: "flex", width: "40%" }} type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
          <input style={{ display: "flex", width: "40%" }} type="text" name="contato" placeholder="Contato" value={formData.contato} onChange={handleChange} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
          <input type="text" name="endereco" placeholder="Endereço" value={formData.endereco} onChange={handleChange} required />
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
          <input type="text" name="detalhes" placeholder="Detalhes" value={formData.detalhes} onChange={handleChange} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <input type="text" name="razaoSocial" placeholder="Razão Social" value={formData.razaoSocial} onChange={handleChange} required />
          <input type="text" name="servico" placeholder="Serviço" value={formData.servico} onChange={handleChange} required />
          <input type="number" name="parcelas" placeholder="Parcelas" value={formData.parcelas} onChange={handleChange} required />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
          <input type="date" name="cobranca" placeholder="Cobrança" value={formData.cobranca} onChange={handleChange} required />
          <input type="date" name="dataPagamento" placeholder="Data de Pagamento" value={formData.dataPagamento} onChange={handleChange} required />
          <input type="date" name="alertaPagamento" placeholder="Alerta de Pagamento" value={formData.alertaPagamento} onChange={handleChange} required />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", margin: "5vh 5vw" }}>
          <button type="submit">Cadastrar</button>
        </div>
      </form>
      {showPopup && <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#ffffff", padding: "20px", borderRadius: "8px", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)" }}>{popupMessage}</div>}
    </div>
  );
}

export default FormFornecedor;
