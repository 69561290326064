import React, { useState, useEffect } from "react";
import { saveProjeto, updateProjeto, findIdProjeto } from '../../services/ProjetoCalls.js';

function FormProjeto({ id }) {
    const [formData, setFormData] = useState({
        numero: "",
        identificacao: "",
        responsavel: "",
        servicos: "",
        tipo: "",
        categoria: "",
        status: "",
        prioridade: "",
        dataInicio: "",
        dataTermino: "",
        dataEntrega: "",
        desempenho: "",
        custo: "",
        rendimento: "",
        investido: "",
        observacoes: ""
    });

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");

    const token = sessionStorage.getItem('token');

    const fetchProjetoData = async () => {
        try {
            const projetoData = await findIdProjeto(id);
            if (projetoData) {
                setFormData({
                    numero: projetoData.numero,
                    identificacao: projetoData.identificacao,
                    responsavel: projetoData.responsavel,
                    servicos: projetoData.servicos,
                    tipo: projetoData.tipo,
                    categoria: projetoData.categoria,
                    status: projetoData.status,
                    prioridade: projetoData.prioridade,
                    dataInicio: projetoData.dataInicio,
                    dataTermino: projetoData.dataTermino,
                    dataEntrega: projetoData.dataEntrega,
                    desempenho: projetoData.desempenho,
                    custo: projetoData.custo,
                    rendimento: projetoData.rendimento,
                    investido: projetoData.investido,
                    observacoes: projetoData.observacoes
                });
            } else {
                console.error("Projeto não encontrado");
            }
        } catch (error) {
            console.error("Erro ao buscar dados do projeto:", error);
        }
    };

    useEffect(() => {
        if (id >= 1) {
            fetchProjetoData();
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id >= 1) {
                await updateProjeto(id, formData, token);
                setPopupMessage("Projeto atualizado com sucesso");
            } else {
                await saveProjeto(formData, token);
                setPopupMessage("Projeto criado com sucesso");
            }
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 3000);
        } catch (error) {
            console.error("Erro ao salvar/atualizar projeto:", error);
            setPopupMessage("Erro ao salvar/atualizar projeto");
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 3000);
        }
    };

    return (
        <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input style={{ display: "flex", width: "40%" }} type="text" name="numero" placeholder="Número" value={formData.numero} onChange={handleChange} required />
                    <input style={{ display: "flex", width: "40%" }} type="text" name="identificacao" placeholder="Identificação" value={formData.identificacao} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input style={{ display: "flex", width: "40%" }} type="text" name="responsavel" placeholder="Responsável" value={formData.responsavel} onChange={handleChange} required />
                    <input style={{ display: "flex", width: "40%" }} type="text" name="servicos" placeholder="Serviços" value={formData.servicos} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input type="text" name="tipo" placeholder="Tipo" value={formData.tipo} onChange={handleChange} required />
                    <input type="text" name="categoria" placeholder="Categoria" value={formData.categoria} onChange={handleChange} required />
                    <input type="text" name="status" placeholder="Status" value={formData.status} onChange={handleChange} required />
                    <input type="text" name="prioridade" placeholder="Prioridade" value={formData.prioridade} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input type="date" name="dataInicio" placeholder="Data de Início" value={formData.dataInicio} onChange={handleChange} required />
                    <input type="date" name="dataTermino" placeholder="Data de Término" value={formData.dataTermino} onChange={handleChange} required />
                    <input type="date" name="dataEntrega" placeholder="Data de Entrega" value={formData.dataEntrega} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
                    <input type="text" name="desempenho" placeholder="Desempenho" value={formData.desempenho} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input type="text" name="custo" placeholder="Custo" value={formData.custo} onChange={handleChange} required />
                    <input type="text" name="rendimento" placeholder="Rendimento" value={formData.rendimento} onChange={handleChange} required />
                    <input type="text" name="investido" placeholder="Investido" value={formData.investido} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
                    <input type="text" name="observacoes" placeholder="Observações" value={formData.observacoes} onChange={handleChange} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", margin: "5vh 5vw" }}>
                    <button type="submit">Cadastrar</button>
                </div>
            </form>
            {showPopup && <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#ffffff", padding: "20px", borderRadius: "8px", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)" }}>{popupMessage}</div>}
        </div>
    );
}

export default FormProjeto;
