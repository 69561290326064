import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { listClientes, deleteCliente } from '../../services/ClienteCalls.js';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { VictoryPie } from 'victory';

function ViewClient() {
  const [clientes, setClientes] = useState([]);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const token = sessionStorage.getItem('token');

        if (!token) {
          throw new Error('Token de autenticação não encontrado');
        }

        const data = await listClientes(token);
        setClientes(data);
      } catch (error) {
        console.error('Erro ao obter a lista de clientes:', error);
      }
    };
    fetchClientes();
  }, []);

  const handleDeleteCliente = async (id) => {
    try {
      const token = sessionStorage.getItem('token');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      await deleteCliente(id, token);
      
      const updatedClientes = clientes.filter(cliente => cliente.id !== id);
      setClientes(updatedClientes);
    } catch (error) {
      console.error('Erro ao deletar o cliente:', error);
    }
  };

  const setSessionStorageItem = (pageName, id) => {
    sessionStorage.setItem("pageName", pageName);
    sessionStorage.setItem("id", id);
  };

  const roleData = clientes.reduce((acc, cliente) => {
    const role = cliente.role;
    if (!acc[role]) {
      acc[role] = 0;
    }
    acc[role]++;
    return acc;
  }, {});

  const chartData = Object.keys(roleData).map(role => ({
    x: role,
    y: roleData[role],
    label: `${role}: ${roleData[role]}`
  }));

  return (
    <div style={{ margin: '0 auto', padding: '1rem', width: "80%", maxWidth: '100%', backgroundColor: '#f7f7f7', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <button onClick={() => setShowChart(!showChart)} style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
        {showChart ? 'Mostrar Tabela' : 'Mostrar Gráfico'}
      </button>
      {showChart ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60vh', marginTop: '2rem' }}>
          <div style={{ width: '100%', maxWidth: '800px' }}>
            <h3 style={{ textAlign: 'center' }} >Distribuição de Clientes por Role</h3>
                    <VictoryPie
                      data={chartData}
                      colorScale={["tomato", "orange", "gold", "cyan", "navy"]}
                      style={{ labels: { fill: "black", fontSize: 12, fontWeight: "bold" } }}
                      labelRadius={180}
                    />
          </div>
        </div>
      ) : (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#007bff', color: 'white', textAlign: 'left' }}>
              <th style={{ padding: '0.5rem' }}>ID</th>
              <th style={{ padding: '0.5rem' }}>Nome</th>
              <th style={{ padding: '0.5rem' }}>Nascimento</th>
              <th style={{ padding: '0.5rem' }}>Senha</th>
              <th style={{ padding: '0.5rem' }}>Email</th>
              <th style={{ padding: '0.5rem' }}>Contato</th>
              <th style={{ padding: '0.5rem' }}>Endereço</th>
              <th style={{ padding: '0.5rem' }}>Role</th>
              <th style={{ padding: '0.5rem' }}>Detalhes</th>
              <th style={{ padding: '0.5rem' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {clientes.map(cliente => (
              <tr key={cliente.id} style={{ borderBottom: '1px solid #dee2e6' }}>
                <td style={{ padding: '0.5rem' }}>{cliente.id}</td>
                <td style={{ padding: '0.5rem' }}>{cliente.nome}</td>
                <td style={{ padding: '0.5rem' }}>{cliente.nascimento}</td>
                <td style={{ padding: '0.5rem' }}>{cliente.senha}</td>
                <td style={{ padding: '0.5rem' }}>{cliente.email}</td>
                <td style={{ padding: '0.5rem' }}>{cliente.contato}</td>
                <td style={{ padding: '0.5rem' }}>{cliente.endereco}</td>
                <td style={{ padding: '0.5rem' }}>{cliente.role}</td>
                <td style={{ padding: '0.5rem' }}>{cliente.detalhes}</td>
                <td style={{ padding: '0.5rem' }}>
                  <EditIcon onClick={() => setSessionStorageItem("FormClient", cliente.id)} style={{ marginRight: '0.5rem', backgroundColor: '#ffc107', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Editar
                  </EditIcon>
                  <ClearIcon onClick={() => handleDeleteCliente(cliente.id)} style={{ backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Deletar
                  </ClearIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Link to="/Register/Client" style={{ textDecoration: 'none' }}>
        <button type="button" style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Cadastrar Cliente
        </button>
      </Link>
    </div>
  );
}

export default ViewClient;
