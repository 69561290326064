const baseUrl = 'https://ar3biai-d5c7f4185f18.herokuapp.com';

export const deleteUsuario = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/usuario/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        console.error('Erro ao excluir usuário:', error);
    }
};

export const listUsuarios = async (token) => {
    try {
        const response = await fetch(`${baseUrl}/usuario/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Erro ao listar usuários:', response.statusText);
        }

        return await response.json();
    } catch (error) {
        console.error('Erro ao listar usuários:', error);
        throw error;
    }
};

export const saveUsuario = async (usuario, token) => {
    try {
        const response = await fetch(`${baseUrl}/usuario/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(usuario)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao salvar usuário:', error);
    }
};

export const updateUsuario = async (id, usuario, token) => {
    try {
        const response = await fetch(`${baseUrl}/usuario/update/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(usuario)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao atualizar usuário:', error);
    }
};

export const findIdUsuario = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/usuario/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Erro ao encontrar usuário:', response.statusText);
        }

        if (response.status === 204) {
            return null;
        }

        return response.json();
    } catch (error) {
        console.error('Erro ao encontrar usuário:', error);
        throw error;
    }
};

export default { deleteUsuario, listUsuarios, saveUsuario, updateUsuario, findIdUsuario };
