import React, { useState, useEffect } from "react";
import { saveContrato, updateContrato, findIdContrato } from '../../services/ContratoCalls.js';

function FormContract({ id }) {
    const [formData, setFormData] = useState({
        numero: "",
        identificacao: "",
        tipoContrato: "",
        vigencia: "",
        pregao: "",
        statusContrato: "",
        dispensa: "",
        vencimento: "",
        alertaPagamento: "",
        notaFiscal: ""
    });

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");

    const token = sessionStorage.getItem('token');

    const fetchContratoData = async () => {
        try {
            const contratoData = await findIdContrato(id, token);
            if (contratoData) {
                setFormData({
                    numero: contratoData.numero,
                    identificacao: contratoData.identificacao,
                    tipoContrato: contratoData.tipoContrato,
                    vigencia: contratoData.vigencia,
                    pregao: contratoData.pregao,
                    statusContrato: contratoData.statusContrato,
                    dispensa: contratoData.dispensa,
                    vencimento: contratoData.vencimento,
                    alertaPagamento: contratoData.alertaPagamento,
                    notaFiscal: contratoData.notaFiscal
                });
            }
        } catch (error) {
            console.error("Erro ao buscar dados do Contrato:", error);
        }
    };

    useEffect(() => {
        if (id >= 1) {
            fetchContratoData();
        }
    }, [id, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id >= 1) {
                await updateContrato(id, formData, token);
                setPopupMessage("Contrato atualizado com sucesso");
            } else {
                await saveContrato(formData, token);
                setPopupMessage("Contrato criado com sucesso");
            }
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 3000);
        } catch (error) {
            console.error("Erro ao salvar/atualizar Contrato:", error);
            setPopupMessage("Erro ao salvar/atualizar Contrato");
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 3000);
        }
    };

    return (
        <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input type="text" name="numero" placeholder="Número" value={formData.numero} onChange={handleChange} required />
                    <input type="text" name="identificacao" placeholder="Identificação" value={formData.identificacao} onChange={handleChange} required />
                    <input type="text" name="tipoContrato" placeholder="Tipo de Contrato" value={formData.tipoContrato} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input style={{ display: "flex", width: "40%" }} type="text" name="vigencia" placeholder="Vigência" value={formData.vigencia} onChange={handleChange} required />
                    <input style={{ display: "flex", width: "40%" }} type="text" name="pregao" placeholder="Pregão" value={formData.pregao} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input style={{ display: "flex", width: "40%" }} type="text" name="statusContrato" placeholder="Status do Contrato" value={formData.statusContrato} onChange={handleChange} required />
                    <input style={{ display: "flex", width: "40%" }} type="text" name="dispensa" placeholder="Dispensa" value={formData.dispensa} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", margin: "5vh 5vw" }}>
                    <input type="date" name="vencimento" placeholder="Vencimento" value={formData.vencimento} onChange={handleChange} required />
                    <input type="date" name="alertaPagamento" placeholder="Alerta de Pagamento" value={formData.alertaPagamento} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "5vh 5vw" }}>
                    <input type="text" name="notaFiscal" placeholder="Nota Fiscal" value={formData.notaFiscal} onChange={handleChange} required />
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", margin: "5vh 5vw" }}>
                    <button type="submit">Cadastrar</button>
                </div>
            </form>
            {showPopup && <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#ffffff", padding: "20px", borderRadius: "8px", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)" }}>{popupMessage}</div>}
        </div>
    );
}

export default FormContract;
