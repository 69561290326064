import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { listPF, deletePF } from '../../services/PessoaFisicaCalls.js';
import { listPJ, deletePJ } from '../../services/PessoaJuridicaCalls.js';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { VictoryPie } from 'victory';

function ViewPessoas() {
  const [pessoas, setPessoas] = useState([]);
  const [tipoPessoa, setTipoPessoa] = useState('');
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const fetchPessoas = async () => {
      try {
        const token = sessionStorage.getItem('token');

        if (!token) {
          throw new Error('Token de autenticação não encontrado');
        }

        const dataPF = await listPF(token);
        const dataPJ = await listPJ(token);

        const pessoasFisicas = dataPF.map(pessoa => ({ ...pessoa, tipoPessoa: 'Pessoa Física' }));
        const pessoasJuridicas = dataPJ.map(pessoa => ({ ...pessoa, tipoPessoa: 'Pessoa Jurídica' }));

        setPessoas([...pessoasFisicas, ...pessoasJuridicas]);
      } catch (error) {
        console.error('Erro ao obter a lista de pessoas:', error);
      }
    };
    fetchPessoas();
  }, []);

  const handleDeletePessoa = async (id, tipoPessoa) => {
    try {
      const token = sessionStorage.getItem('token');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      if (tipoPessoa === 'Pessoa Física') {
        await deletePF(id, token);
      } else if (tipoPessoa === 'Pessoa Jurídica') {
        await deletePJ(id, token);
      }

      const updatedPessoas = pessoas.filter(pessoa => pessoa.id !== id);
      setPessoas(updatedPessoas);
    } catch (error) {
      console.error('Erro ao deletar a pessoa:', error);
    }
  };

  const setSessionStorageItem = (pageName, id) => {
    sessionStorage.setItem("pageName", pageName);
    sessionStorage.setItem("id", id);
  };

  // Prepara dados para o gráfico de roles
  const roleData = pessoas.reduce((acc, pessoa) => {
    const tipoPessoa = pessoa.tipoPessoa;
    if (!acc[tipoPessoa]) {
      acc[tipoPessoa] = 0;
    }
    acc[tipoPessoa]++;
    return acc;
  }, {});

  const chartData = Object.keys(roleData).map(tipoPessoa => ({
    x: tipoPessoa,
    y: roleData[tipoPessoa],
    label: `${tipoPessoa}: ${roleData[tipoPessoa]}`
  }));


  return (
    <div style={{ margin: '0 auto', padding: '1rem', width: "80%", maxWidth: '100%', overflowX: 'auto', backgroundColor: '#f7f7f7', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <button onClick={() => setShowChart(!showChart)} style={{ marginTop: '1rem', marginBottom: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
        {showChart ? 'Mostrar Tabela' : 'Mostrar Gráfico'}
      </button>
      {showChart ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60vh', marginTop: '2rem' }}>
        <div style={{ width: '100%', maxWidth: '800px' }}>
          <h3 style={{ textAlign: 'center' }}>Distribuição de Pessoas por Role</h3>
          <VictoryPie
                    data={chartData}
                    colorScale={["tomato", "orange", "gold", "cyan", "navy"]}
                    style={{ labels: { fill: "black", fontSize: 12, fontWeight: "bold" } }}
                    labelRadius={100}
                  />
        </div>
      </div>
      ) : (<table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr style={{ backgroundColor: '#007bff', color: 'white', textAlign: 'left' }}>
          <th style={{ padding: '0.5rem' }}>ID</th>
          <th style={{ padding: '0.5rem' }}>Nome</th>
          <th style={{ padding: '0.5rem' }}>Nascimento</th>
          <th style={{ padding: '0.5rem' }}>Senha</th>
          <th style={{ padding: '0.5rem' }}>Email</th>
          <th style={{ padding: '0.5rem' }}>Contato</th>
          <th style={{ padding: '0.5rem' }}>Endereço</th>
          <th style={{ padding: '0.5rem' }}>Role</th>
          {tipoPessoa && <th style={{ padding: '0.5rem' }}>{tipoPessoa}</th>}
          <th style={{ padding: '0.5rem' }}>Tipo</th>
          <th style={{ padding: '0.5rem' }}>CPF/CNPJ</th>
          <th style={{ padding: '0.5rem' }}>Detalhes</th>
          <th style={{ padding: '0.5rem' }}>Ações</th>
        </tr>
      </thead>
      <tbody>
        {pessoas.map(pessoa => (
          <tr key={pessoa.id} style={{ borderBottom: '1px solid #dee2e6' }}>
            <td style={{ padding: '0.5rem' }}>{pessoa.id}</td>
            <td style={{ padding: '0.5rem' }}>{pessoa.nome}</td>
            <td style={{ padding: '0.5rem' }}>{pessoa.nascimento}</td>
            <td style={{ padding: '0.5rem' }}>{pessoa.senha}</td>
            <td style={{ padding: '0.5rem' }}>{pessoa.email}</td>
            <td style={{ padding: '0.5rem' }}>{pessoa.contato}</td>
            <td style={{ padding: '0.5rem' }}>{pessoa.endereco}</td>
            <td style={{ padding: '0.5rem' }}>{pessoa.role}</td>
            {pessoa.tipoPessoa && <td style={{ padding: '0.5rem' }}>{pessoa.tipoPessoa}</td>}
            <td style={{ padding: '0.5rem' }}>{pessoa.tipoPessoa === 'Pessoa Física' ? pessoa.cpf : pessoa.cnpj}</td>
            <td style={{ padding: '0.5rem' }}>{pessoa.detalhes}</td>
            <td style={{ padding: '0.5rem' }}>
                <EditIcon onClick={() => setSessionStorageItem("FormPessoa", pessoa.id)} style={{ marginRight: '0.5rem', backgroundColor: '#ffc107', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                  Editar
                </EditIcon>
              <ClearIcon onClick={() => handleDeletePessoa(pessoa.id, pessoa.tipoPessoa)} style={{ backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                Deletar
              </ClearIcon>
            </td>
          </tr>
        ))}
      </tbody>
    </table> )}
      <Link to="/Register/Pessoa" style={{ textDecoration: 'none' }}>
        <button type="button" style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Cadastrar Pessoa
        </button>
      </Link>
    </div>
  );
}

export default ViewPessoas;
