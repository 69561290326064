import React, { useState } from "react";
import Logo from "../resources/Logo_005.png";

function Sidebar() {
  const [expandedItems, setExpandedItems] = useState({
    config: false,
    register: false,
    view: false
  });

  const toggleExpand = (item) => {
    setExpandedItems({
      config: false,
      register: false,
      view: false,
      [item]: !expandedItems[item]
    });
  };

  const setSessionStorageItem = (pageName) => {
    sessionStorage.setItem("pageName", pageName);
    sessionStorage.setItem("id", null);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '20%',
      background: "linear-gradient(to right, #4b6cb7, #182848)",
      color: '#fff'
    }}>
       <div
          style={{
            width: "100%",
            height: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{
              maxHeight: "250%",
              maxWidth: "250%",
              objectFit: "contain",
            }}
          />
        </div>
      <ul style={{
        listStyleType: 'none',
        padding: '1rem'
      }}>
        <li style={{
          marginBottom: '1rem'
        }}>
          <button onClick={() => toggleExpand("config")} style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            border: 'none',
            background: 'none',
            color: 'inherit'
          }}>
            <span style={{
              marginRight: '0.5rem',
            }}>{expandedItems.config ? "▼" : "►"}</span>
            Configurações / Sistema
          </button>
          {expandedItems.config && (
            <ul style={{
              listStyleType: 'none',
              paddingLeft: '20px',
              marginTop: '0.5rem'
            }}>
              <li style={{
                marginBottom: '0.5rem'
              }}>
                <button onClick={() => setSessionStorageItem("Settings")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Configurações</button>
              </li>
              <li>
                <button onClick={() => setSessionStorageItem("System")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Sistema</button>
              </li>
            </ul>
          )}
        </li>
        <li style={{
          marginBottom: '1rem'
        }}>
          <button onClick={() => toggleExpand("register")} style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            border: 'none',
            background: 'none',
            color: 'inherit'
          }}>
            <span style={{
              marginRight: '0.5rem'
            }}>{expandedItems.register ? "▼" : "►"}</span>
            Cadastro
          </button>
          {expandedItems.register && (
            <ul style={{
              listStyleType: 'none',
              paddingLeft: '20px',
              marginTop: '0.5rem'
            }}>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("FormUser")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Cadastrar Usuário</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("FormClient")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Cadastrar Cliente</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("FormPessoa")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Cadastrar Pessoa</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("FormFornecedor")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Cadastrar Fornecedor</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("FormFinancial")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Cadastrar Financeiro</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("FormProject")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Cadastrar Projeto</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("FormTask")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Cadastrar Tarefa</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("FormContract")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Cadastrar Contrato</button>
              </li>
            </ul>
          )}
        </li>
        <li>
          <button onClick={() => toggleExpand("view")} style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            border: 'none',
            background: 'none',
            color: 'inherit'
          }}>
            <span style={{
              marginRight: '0.5rem'
            }}>{expandedItems.view ? "▼" : "►"}</span>
            Visualizar
          </button>
          {expandedItems.view && (
            <ul style={{
              listStyleType: 'none',
              paddingLeft: '20px',
              marginTop: '0.5rem'
            }}>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("ViewUser")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Visualizar Usuários</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("ViewClient")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Visualizar Clientes</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("ViewPessoas")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Visualizar Pessoas</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("ViewFornecedor")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Visualizar Fornecedores</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("ViewFinancial")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Visualizar Financeiro</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("ViewProject")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Visualizar Projeto</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("ViewTask")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Visualizar Tarefa</button>
              </li>
              <li style={{
                  color: '#adb5bd'
                }}>
                <button onClick={() => setSessionStorageItem("ViewContract")} style={{
                  textDecoration: 'none',
                  color: '#adb5bd',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer'
                }}>Visualizar Contrato</button>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
