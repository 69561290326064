import React, { useEffect, useState } from "react";
import FormUser from "./Form/FormUser";
import FormClient from "./Form/FormClient";
import FormPessoa from "./Form/FormPessoa";
import FormFornecedor from "./Form/FormFornecedor";
import FormFinancial from "./Form/FormFinancial";
import FormProject from "./Form/FormProjeto";
import FormTask from "./Form/FormTask";
import FormContract from "./Form/FormContract";
import ViewUser from "./View/ViewUser";
import ViewClient from "./View/ViewClient";
import ViewPessoas from "./View/ViewPessoas";
import ViewFornecedor from "./View/ViewFornecedor";
import ViewFinancial from "./View/ViewFinancial";
import ViewProject from "./View/ViewProject";
import ViewTask from "./View/ViewTask";
import ViewContract from "./View/ViewContract";

function MainScreen() {
  const [currentPageName, setCurrentPageName] = useState("");
  const [currentId, setCurrentId] = useState("");

  useEffect(() => {
    const updateSessionStorageValues = () => {
      const storedPageName = sessionStorage.getItem("pageName") || "";
      const storedId = sessionStorage.getItem("id") || "";

      setCurrentPageName(storedPageName);
      setCurrentId(storedId);
    };

    updateSessionStorageValues();

    window.addEventListener("storage", updateSessionStorageValues);

    const interval = setInterval(updateSessionStorageValues, 1000);

    return () => {
      window.removeEventListener("storage", updateSessionStorageValues);
      clearInterval(interval);
    };
  }, []);
//"linear-gradient(to right, #4b6cb7, #182848)"
  return (
    <div style={{ display: "flex", width: "80%", height: "100%", justifyContent: "center", alignItems: "center", background: "#182848" }}>
      {currentPageName === "" || currentPageName === null ? (
        <></>
      ) : (
        <>
          {currentPageName === "FormUser" && <FormUser id={currentId} />}
          {currentPageName === "FormClient" && <FormClient id={currentId} />}
          {currentPageName === "FormPessoa" && <FormPessoa id={currentId} />}
          {currentPageName === "FormFornecedor" && <FormFornecedor id={currentId} />}
          {currentPageName === "FormFinancial" && <FormFinancial id={currentId} />}
          {currentPageName === "FormProject" && <FormProject id={currentId} />}
          {currentPageName === "FormTask" && <FormTask id={currentId} />}
          {currentPageName === "FormContract" && <FormContract id={currentId} />}
          {currentPageName === "ViewUser" && <ViewUser id={currentId} />}
          {currentPageName === "ViewClient" && <ViewClient id={currentId} />}
          {currentPageName === "ViewPessoas" && <ViewPessoas id={currentId} />}
          {currentPageName === "ViewFornecedor" && <ViewFornecedor id={currentId} />}
          {currentPageName === "ViewFinancial" && <ViewFinancial id={currentId} />}
          {currentPageName === "ViewProject" && <ViewProject id={currentId} />}
          {currentPageName === "ViewTask" && <ViewTask id={currentId} />}
          {currentPageName === "ViewContract" && <ViewContract id={currentId} />}
        </>
      )}
    </div>
  );
}

export default MainScreen;
