const baseUrl = 'https://ar3biai-d5c7f4185f18.herokuapp.com';

export const deleteContrato = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/contrato/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao excluir registro do contrato:', response.statusText);
        }
        return response;
    } catch (error) {
        console.error('Erro ao excluir registro do contrato:', error);
        throw error; // Re-throw para tratar o erro onde for chamado
    }
};

export const listContratos = async (token) => {
    try {
        const response = await fetch(`${baseUrl}/contrato/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao listar registros de contratos:', response.statusText);
        }
        return await response.json();
    } catch (error) {
        console.error('Erro ao listar registros de contratos:', error);
        throw error; // Re-throw para tratar o erro onde for chamado
    }
};

export const saveContrato = async (contrato, token) => {
    try {
        const response = await fetch(`${baseUrl}/contrato/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(contrato)
        });
        if (!response.ok) {
            throw new Error('Erro ao salvar registro do contrato:', response.statusText);
        }
        return await response.json();
    } catch (error) {
        console.error('Erro ao salvar registro do contrato:', error);
        throw error; // Re-throw para tratar o erro onde for chamado
    }
};

export const updateContrato = async (id, contrato, token) => {
    try {
        const response = await fetch(`${baseUrl}/contrato/update/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(contrato)
        });
        if (!response.ok) {
            throw new Error('Erro ao atualizar registro do contrato:', response.statusText);
        }
        return await response.json();
    } catch (error) {
        console.error('Erro ao atualizar registro do contrato:', error);
        throw error; // Re-throw para tratar o erro onde for chamado
    }
};

export const findIdContrato = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/contrato/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Erro ao encontrar contrato:', response.statusText);
        }
        if (response.status === 204) {
            return null;
        }
        return await response.json();
    } catch (error) {
        console.error('Erro ao encontrar contrato:', error);
        throw error; // Re-throw para tratar o erro onde for chamado
    }
};

export default { deleteContrato, listContratos, saveContrato, updateContrato, findIdContrato };
