const baseUrl = 'https://ar3biai-d5c7f4185f18.herokuapp.com';

export const deletePF = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/pessoa-fisica/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        console.error('Erro ao excluir registro da pessoa física:', error);
    }
};

export const listPF = async (token) => {
    try {
        const response = await fetch(`${baseUrl}/pessoa-fisica/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao listar registros de pessoas físicas:', error);
    }
};

export const savePF = async (pf, token) => {
    try {
        const response = await fetch(`${baseUrl}/pessoa-fisica/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(pf)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao salvar registro da pessoa física:', error);
    }
};

export const updatePF = async (id, pf, token) => {
    try {
        const response = await fetch(`${baseUrl}/pessoa-fisica/update/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(pf)
        });
        return response.json();
    } catch (error) {
        console.error('Erro ao atualizar registro da pessoa física:', error);
    }
};

export const findIdPF = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/pessoa-fisica/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        
        if (!response.ok) {
            throw new Error('Erro ao encontrar usuário:', response.statusText);
        }

        if (response.status === 204) {
            return null;
        }

        
        return response.json();
    } catch (error) {
        console.error('Erro ao encontrar pessoa física:', error);
    }
};

export default { deletePF, listPF, savePF, updatePF, findIdPF };
